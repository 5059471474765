class Config {
  static get config() {

    let ENV = 'prod';
    
    let production;
    let sso;
    let serverUrl;
    const url = '192.168.1.177';

    if (ENV==='prod') {
      production  = true;
      sso         = true;
      serverUrl   = '';
    } else if (ENV==='dev_aws') {
      production  = false;
      sso         = true;
      serverUrl   = '';
    } else if (ENV==='dev_local') {
      production  = false;
      sso         = false;
      serverUrl   = '';
    } else if (ENV==='dev_local_node') {
      production  = false;
      sso         = false;
      serverUrl   = `http://${url}:5000`;
    }

    return({
      production:   production,
      sso:          sso,
      serverUrl:    serverUrl,
    });
  }
}

export default Config;
